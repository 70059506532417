import Annonce from "../../assets/3 - acheter/Annonce detaillee-2.png";
import Estimation from "../../assets/3 - acheter/Estimation vente.png";
import Visite from "../../assets/visite/planifier.png";
import { DisplayRoadMapSection } from "../../utils/FlatWay.utils";
import { BuySections } from "../FlatWay/FlatWay.Buy.Component";
import React from "react";
import Consultation from "../../assets/1 - landing page/Landing2@2x.png";
import Geometry from "../../assets/geometry/blob.svg";
import Geometry2 from "../../assets/geometry/blob2.svg";
import Acheter from "../../assets/Acheter 2.jpg";
import { MdCompareArrows, MdOutlineSell } from "react-icons/md";
import { AiOutlinePercentage } from "react-icons/ai";
import { CiCalendar } from "react-icons/ci";

/**
 * @name PreparationSection
 * @description Section de préparation pour l'achat
 * @constructor
 */
export const PreparationSection = () => {
  const images = [Annonce, Estimation, Visite];
  return (
    <section
      className={"flex min-h-[300px] h-auto flex-col gap-4 items-center py-10"}
    >
      <DisplayRoadMapSection
        images={images}
        sections={BuySections}
        title={"Préparer l'achat"}
      />
    </section>
  );
};

/**
 * @name OriginSection
 * @description Section sur l'origine des annonces
 * @constructor
 */
export const OriginSection = () => {
  return (
    <section
      className={
        "flex lg:flex-row flex-col-reverse gap-10 lg:px-0 px-2 lg:gap-8 items-center py-10"
      }
    >
      <span className={"flex flex-col gap-4 w-full lg:w-[600px]"}>
        <h1 className={"text-xl md:text-3xl font-bold mb-5"}>
          Origine des annonces sur Flatway
        </h1>
        <p className={"text-sm md:text-base"}>
          Les annonces sont enregistrées sur Flatway par les vendeurs.
          <br />
          <br />
          Certains vendeurs ont opté pour un seul professionnel pour la vente,
          tandis que d'autres ont confié la vente à plusieurs professionnels.{" "}
          <br />
          <br />
          Certains ont choisi d’assurer eux-mêmes les visites et de bénéficier
          des honoraires à taux réduits, tandis que d’autres ont fait le choix
          de confier les visites aux professionnels en charge de la vente.
          <br />
          <br />
          Dans les deux cas le professionnel est l’interlocuteur du vendeur et
          des acheteurs, il assure conseil et suivi jusqu’à la signature chez le
          notaire.
        </p>
      </span>
      <aside className={"w-[300px]"}>
        <img
          alt={"Origine"}
          src={Consultation}
          className={"w-full object-cover"}
        />
      </aside>
    </section>
  );
};

/**
 * @name HowToBuyHeader
 * @description Header de la page d'achat
 * @constructor
 */
export const HowToBuyHeader = () => {
  const buyAdvantages = [
    {
      text: "Achetez au juste prix en vous appuyant sur notre estimation",
      icon: <MdOutlineSell className="text-[30px]" />,
    },
    {
      text: "Choisissez les biens en fonction du type d’honoraires, standards ou à taux réduits",
      icon: <AiOutlinePercentage className="text-[30px]" />,
    },
    {
      text: "Prenez vos rendez-vous en ligne",
      icon: <CiCalendar className="text-[30px]" />,
    },
    {
      text: "Comparez le prix de chaque bien consulté avec celui des ventes récentes autour de l’adresse",
      icon: <MdCompareArrows className="text-[30px]" />,
    },
  ];

  return (
    <header
      className={
        "bg-gradient-to-tl overflow-hidden relative via-dark-red to-dark-red from-[#d73e3e] py-10 h-auto flex flex-col items-center w-full"
      }
    >
      <img
        src={Geometry}
        alt={"Blob"}
        className={
          "select-none w-[400px] absolute top-0 z-0 -left-[2vw] drop-shadow-lg"
        }
      />
      <img
        src={Geometry2}
        alt={"Blob"}
        className={
          "w-[600px] select-none absolute z-0 -right-36 bottom-[-10vw] drop-shadow-lg"
        }
      />
      <div
        className={
          "h-auto xl:px-0 px-4 xl:grid xl:grid-cols-flatwaySection z-10 text-white xl:w-[1200px] items-center font-bold gap-10 justify-center"
        }
      >
        <h1 className={"text-2xl md:text-4xl col-span-6 w-full"}>
          Nous garantissons la facilité et la transparence de votre expérience
          d'achat{" "}
        </h1>
        <aside
          className={"col-span-6 xl:mt-0 mt-10 relative h-[300px] sm:h-[500px]"}
        >
          <img
            alt={"Acheter"}
            src={Acheter}
            className={
              "w-full h-full object-cover absolute z-20 rounded-customBlobImage"
            }
          />
          <div
            className={
              "rounded-customBlobTriangle3 top-0 z-10 h-[200px] absolute w-[200px] bg-light-red"
            }
          />
        </aside>
      </div>
      <section
        className={
          "w-full flex flex-col z-10 py-10 justify-center items-center"
        }
      >
        <span>
          <ul
            className={
              "font-medium sm:grid grid-cols-2 text-white xl:px-0 px-2 gap-5 w-full xl:w-[1200px]"
            }
          >
            {buyAdvantages.map((item, index) => (
              <li
                key={index}
                className={
                  "gap-3 flex flex-col text-sm md:text-base items-start sm:mt-0 mt-4 xl:w-[25vw] justify-start"
                }
              >
                {item.icon}
                {item.text}
              </li>
            ))}
          </ul>
        </span>
      </section>
    </header>
  );
};
