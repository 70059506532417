import React from "react";
import { FlatWayHeader } from "../../../utils/FlatWay.utils";

/**
 * @name FlatwayIntroPhone
 * @description Section d'introduction de la page Flatway pour les mobiles
 * @constructor
 */
export const FlatwayIntroPhone = () => {
  return (
    <div>
      <FlatWayHeader
        desc={"Gratuits pour les particuliers "}
        title="Découvrir nos services"
      />
      <div className={"bg-dark-red text-white text-sm p-2"}>
        <p>
          Flatway est une plateforme qui vous permet de vendre ou d’acheter en
          utilisant les services des professionnels de l’immobilier.
        </p>
      </div>
      <span className={"text-sm flex flex-col gap-3"}>
        <p>
          Les professionnels sont les agents immobiliers, notaires, mandataires
          et syndics de copropriété ayant souscrit un contrat de partenariat
          avec Flatway.
        </p>
        <p>
          Chaque professionnel renseigne son barème d’honoraires standards et un{" "}
          <span className={"underline"}>
            barème d’honoraires à taux réduits
          </span>{" "}
          à destination des vendeurs désireux d’assurer eux-mêmes les visites.
        </p>
        <p>
          L’application Flatway offre aux particuliers, vendeurs et acheteurs,
          une démarche avantageuse et simplifiée pour vendre ou acheter un bien
          immobilier :
        </p>
      </span>
    </div>
  );
};

/**
 * @name FlatwayPresePhone
 * @description Présentation de la page Flatway pour les mobiles
 * @constructor
 */
export const FlatwayPresePhone = () => {
  return (
    <div className={"h-auto w-full text-sm"}>
      <section className={"flex flex-col gap-4"}>
        <FlatWayHeader title={"Vendeur"} desc={""} />
        <div className={"w-auto h-auto bg-light-red p-4 rounded-lg"}>
          <ul className={"list-[√] ml-4"}>
            <li>
              Confie la vente de son bien à un ou plusieurs professionnels, sans
              se déplacer, par simples clics
            </li>
            <li>
              Peut assurer lui-même les visites, tout en confiant la vente au
              professionnel, en contrepartie d’une baisse significative des
              honoraires
            </li>
            <li>
              Signe électroniquement le mandat de vente avec le(s)
              professionnel(s) choisi(s)
            </li>
            <li>
              Gère les rendez-vous de visites et négocie directement avec les
              acheteurs potentiels à partir de son espace dédié
            </li>
          </ul>
        </div>
        <p>
          Le vendeur est libre de choisir le professionnel dans la liste ou, à
          défaut, de demander au professionnel souhaité de s’inscrire sur la
          plateforme avant de mettre en vente son bien
        </p>
      </section>
      <section className={"flex flex-col gap-4"}>
        <FlatWayHeader title={"Acheteur"} desc={""} />
        <div className={"w-auto h-auto bg-light-red p-4 rounded-lg"}>
          <ul className={"list-[√] ml-4"}>
            <li>Prend rendez-vous en ligne pour visiter, sans intermédiaire</li>
            <li> Utilise notre estimation pour chaque bien en vente</li>
            <li>
              Consulte la liste des biens vendus à l’adresse et à proximité de
              chaque bien en vente
            </li>
            <li>
              Gère les rendez-vous de visites et négocie directement avec les
              vendeurs à partir de son espace dédié
            </li>
          </ul>
        </div>
        <p>
          Flatway ne vend pas de l’immobilier, les honoraires sont à payer
          directement aux professionnels le jour de la signature chez le notaire
          <br />
          <br />
          Les services de mise en relation par Flatway sont gratuits pour les
          utilisateurs, vendeurs et acheteurs.
          <br />
          <br /> L’utilisateur peut être, à la fois, vendeur et acheteur
        </p>
      </section>
    </div>
  );
};

/**
 * @name PhoneSectionOne
 * @description Section 1 de la page Flatway pour les mobiles (Devenez vendeur)
 * @constructor
 */
const PhoneSectionOne = () => {
  return (
    <div className={"h-auto w-full"}>
      <h1 className={"flex gap-1 items-end"}>
        <p className={"font-bold text-dark-red text-xl"}>1</p>Préparer la vente
      </h1>
      <ol className={"list-decimal list-inside text-sm flex flex-col gap-2"}>
        <li>
          Consulter le prix réel des ventes à l’adresse et à proximité de votre
          bien
        </li>
        <li>
          Consulter la liste des professionnels et comparer leurs honoraires
        </li>
        <li>Définir le prix net vendeur</li>
      </ol>
    </div>
  );
};

/**
 * @name PhoneSectionTwo
 * @description Section 2 de la page Flatway pour les mobiles (Vendre en 4 étapes simples)
 * @constructor
 */
const PhoneSectionTwo = () => {
  return (
    <div className={"h-auto w-full"}>
      <h1 className={"flex gap-1 items-end"}>
        <p className={"font-bold text-dark-red text-xl"}>2</p>Vendre en 4 étapes
        simples
      </h1>
      <ul className={"list-decimal list-inside text-sm flex flex-col gap-2"}>
        <li>Choisir un professionnel dans la liste</li>
        <li>
          Choisir un type d’honoraires
          <span className={"text-dark-red"}>*</span>
        </li>
        <li>
          Enregistrer l’annonce : par simples clics, votre annonce apparaît
          détaillée et sans aucun superflu
        </li>
        <li>
          Signer électroniquement le mandat de vente avec le
          professionnel choisi
        </li>
      </ul>
      <div className={"w-full flex justify-center"}>
        <div
          className={"w-[350px] bg-light-red rounded-[5px] p-2 text-justify"}
        >
          <ol className={"text-sm list-none flex flex-col gap-2"}>
            <li className="before:content-['*'] before:mr-2">
              Honoraires à taux réduits, visites assurées par le vendeur sans la
              présence du professionnel ; visite Sans accompagnateur
            </li>
            <li className="before:content-['*'] before:mr-2">
              Honoraires standards, visites assurées par le professionnel ;
              visite Avec accompagnateur
            </li>
          </ol>
          <p className={"text-sm leading-normal"}>
            Dans les deux cas, le professionnel assure suivi et conseil, et
            permet aux acheteurs intéressés de consulter les documents relatifs
            au bien
          </p>
        </div>
      </div>
    </div>
  );
};

/**
 * @name PhoneSectionThree
 * @description Section 3 de la page Flatway pour les mobiles (Les avantages avec FlatWay)
 * @constructor
 */
const PhoneSectionThree = () => {
  return (
    <div className={"h-auto w-full"}>
      <h1 className={"flex gap-1 items-end"}>
        <p className={"font-bold text-dark-red text-xl"}>3</p>Les avantages avec
        FlatWay
      </h1>
      <ol className={"list-decimal list-inside text-sm flex flex-col gap-2"}>
        <li>
          Les données (identité, mail, mobile et adresse) ne sont connues que du
          professionnel choisi
        </li>
        <li>Vendre au juste prix en s’appuyant sur notre estimation</li>
        <li>Optimiser les coûts d’honoraires</li>
        <li>
          Gagner du temps, aucun déplacement chez le professionnel n’est
          obligatoire
        </li>
        <li>
          Confier la vente à plusieurs professionnels par simple duplication de
          l’annonce
        </li>
        <li>Gérer les rendez-vous et visites à partir de l’espace client</li>
        <li>
          Négocier directement avec les acheteurs à partir de votre espace
        </li>
        <li>Retrouver l’historique des visites</li>
        <li>Être alerté par des rappels automatiques de rendez-vous</li>
      </ol>
    </div>
  );
};

/**
 * @name FlatwaySellPhone
 * @description Affichage des différentes sections de Vendre de la page Flatway pour les mobiles
 * @constructor
 */
export const FlatwaySellPhone = () => {
  return (
    <div className={"h-auto flex flex-col gap-6"}>
      <FlatWayHeader
        title={"Devenez vendeur"}
        desc={"Les services et les avantages Flatway fournis pour le vendeur :"}
      />
      <PhoneSectionOne />
      <PhoneSectionTwo />
      <PhoneSectionThree />
    </div>
  );
};

/**
 * @name FlatwayBuyComponentPhone
 * @description Explication des avantages de l'acheteur sur la page Flatway pour les mobiles
 * @constructor
 */
export const FlatwayBuyComponentPhone = () => {
  return (
    <div className={"sm:hidden block"}>
      <FlatWayHeader
        title={"Devenez acheteur"}
        desc={"Les services et les avantages Flatway fournis pour l’acheteur :"}
      />
      <h1 className={"flex gap-1 items-end"}>
        <p className={"font-bold text-dark-red text-xl"}>1</p>Préparer l'achat
      </h1>
      <ol className={"list-decimal list-inside text-sm flex flex-col gap-2"}>
        <li>
          Comparer le prix de chaque bien consulté avec celui des ventes
          récentes à l’adresse et à proximité
        </li>
        <li>Consulter l’estimation des biens en vente</li>
        <li>Localiser chaque annonce par le nom de la voie</li>
      </ol>
      <h1 className={"flex gap-1 items-end"}>
        <p className={"font-bold text-dark-red text-xl"}>2</p>Les avantages
        FlatWay
      </h1>
      <ol className={"list-disc list-inside text-sm flex flex-col gap-2"}>
        <li>Acheter au juste prix</li>
        <li>
          Choisir les biens en fonction du type d’honoraires, standards ou à
          taux réduits
        </li>
        <li>Gérer les rendez-vous et visites à partir de l’espace client</li>
        <li>Négocier directement avec les vendeurs à partir de votre espace</li>
        <li>Retrouver les historiques des visites</li>
        <li>Être alerté par des rappels automatiques de rendez-vous</li>
      </ol>
    </div>
  );
};

/**
 * @name FlatwayVisitPhone
 * @description Section de visite de la page Flatway pour les mobiles
 * @constructor
 */
export const FlatwayVisitPhone = () => {
  return (
    <section className={"text-sm"}>
      <FlatWayHeader title={"La visite des biens"} desc={""} />
      <div>
        <p>
          La plateforme offre aux parties, acheteur, vendeur et professionnel,
          une solution complète de gestion des visites, solution regroupée dans
          un espace dédié : ‘Mes échanges’.
          <br />
          <br />
          De la demande de visite formulée par l’acheteur jusqu’au rendez-vous
          de signature chez le notaire, les services de la plateforme échangent
          avec les parties :
        </p>
        <ul className={"bg-light-red p-4 list-disc list-inside rounded-lg"}>
          <li>
            Si le vendeur a opté pour une vente sans accompagnateur, assurer
            lui-même les visites, les services Flatway échangent avec l’acheteur
            et le vendeur. Le professionnel quant à lui, est seulement tenu
            informé
          </li>
          <li>
            Si le vendeur a opté pour la solution avec accompagnateur, confier
            la visite au professionnel, les services Flatway échangent avec
            l’acheteur et le professionnel. Le vendeur quant à lui, est
            seulement tenu informé
          </li>
        </ul>
        <p>
          A noter : Par mesure de sécurité, l'application ne fournit aux
          acquéreurs ni l'adresse du bien ni les codes d'accès de l'immeuble.
          Seul un point de rencontre, choisi par le vendeur (ou le
          professionnel), est communiqué à l’acheteur.
        </p>
      </div>
      <div>
        <h1
          className={
            "bg-dark-red text-white px-2 py-4 rounded-t-lg text-xl w-full"
          }
        >
          La visite
        </h1>
        <ul className={"border py-4 px-2 rounded-b-lg flex flex-col gap-8"}>
          <li>
            <p>
              <span className={"text-dark-red font-medium text-base"}>
                La demande de visite
              </span>
              <br />
              <br />
              L’acheteur consulte les annonces et propose des dates pour visiter
              un bien.
              <br />
              <br /> La demande de visite est placée En attente dans l’espace «
              Mes Echanges » de l’acheteur, du vendeur et du professionnel. Le
              vendeur et le professionnel sont alertés par notifications. <br />
              <br />
              Le vendeur (ou le professionnel) choisit la date de visite.
            </p>
          </li>
          <li>
            <p>
              <span className={"text-dark-red font-medium text-base"}>
                La visite planifiée
              </span>
              <br />
              <br />
              La date de visite confirmée est placée en Planifier dans l’espace
              ‘Mes Echanges’ des parties.
              <br />
              <br />
              Si une des parties annule ou déplace le rendez-vous, les autres
              parties sont aussitôt notifiés.
              <br />
              <br />
              La veille de la visite, des notifications de rappels sont envoyées
              aux parties.
            </p>
          </li>
          <li>
            <p>
              <span className={"text-dark-red font-medium text-base"}>
                La visite
              </span>
              <br />
              <br />
              L’acheteur et le vendeur (ou le professionnel) doivent confirmer
              leur présence
              <br />
              <br />
              Après confirmation des parties, un point de rencontre, choisi par
              le vendeur (ou le professionnel), est communiqué à l’acheteur.
              <br />
              <br />
              L’identité de l’acheteur est confirmée grâce à la reconnaissance
              du QR code accessible depuis l’espace visite
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

/**
 * @name FlatwayOfferPhone
 * @description Section d'offre d'achat-vente de la page Flatway pour les mobiles
 * @constructor
 */
export const FlatwayOfferPhone = () => {
  return (
    <div>
      <FlatWayHeader title="Offre d’achat-vente" desc={""} />
      <p>
        Acheteur et vendeur négocient directement{" "}
        <span className={"font-bold"}>
          sans l’intermédiaire du professionnel
        </span>
        .
      </p>
      <div className="w-full flex flex-col items-center gap-8">
        <span className="w-full lg:w-[800px] bg-[#F4E5E5] p-2 text-[14px]">
          <h2>
            Après la visite l’acheteur intéressé formule une offre d’achat :
          </h2>
          <ul className="list-disc list-inside">
            <li>Le vendeur et le professionnel sont alors informés.</li>
            <li>
              Le vendeur, accepte l’offre, refuse ou formule une contre-offre de
              vente.
            </li>
            <li>
              A son tour, l’acheteur accepte, refuse ou formule une nouvelle
              offre.
            </li>
          </ul>
          <h2>In fine,</h2>
          <ul className="list-disc list-inside">
            <li>
              L’offre d’achat ou de vente est acceptée, auquel cas, le
              professionnel prend le relais et transmet le dossier au notaire.
            </li>
            <li>
              Ou bien, la dernière offre est refusée ; la négociation est
              terminée.
            </li>
          </ul>
        </span>
        <p className="w-full lg:w-[800px] text-base font-semibold text-dark-red">
          Le professionnel aura un accès instantané aux offres et au mode de
          financement. Il intervient uniquement comme conseil pour les parties.
        </p>
      </div>
    </div>
  );
};
