import { VisiteCard } from "../../utils/FlatWay.utils";
import React, { useState } from "react";
import VisiteP from "../../assets/4 - visiter/partenaire/Planifier-Partenaire.png";
import Demande from "../../assets/4 - visiter/partenaire/En attente-Partenaire.png";
import Emprunt from "../../assets/offer/offer.png";
import Visite from "../../assets/4 - visiter/partenaire/Visiter-Partenaire.png";
import partner from "../../assets/5 - offres/partenaire/Offre-partenaire@2x.png";
import accept from "../../assets/5 - offres/partenaire/Offre-partenaire 2@2x.png";
import blob from "../../assets/geometry/blob (3).svg";
import blob2 from "../../assets/geometry/blob (5).svg";
import blob3 from "../../assets/geometry/blob2.svg";
import Phone from "../../assets/Picture1.png";
import { SAlogo } from "../../utils/utils.components";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosTrendingUp,
  IoMdInformation,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { IoPeople } from "react-icons/io5";
import { HiOutlinePencil } from "react-icons/hi";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { AdvantageContent } from "../../interfaces/FlatWayPro.interface";

/**
 * @name ModelSection
 * @description Section du modèle de FlatwayPro
 * @constructor
 */
export const ModelSection = () => {
  return (
    <div
      className={
        "xl:h-auto w-full flex flex-col mt-10 justify-center items-center gap-7"
      }
    >
      <header
        className={
          "w-full sm:w-[70%] rounded-lg p-4 flex flex-col items-center"
        }
      >
        <h1
          className={
            "text-3xl sm:text-clampProIntroTitle text-darkRedTitle leading-tight text-center font-bold"
          }
        >
          Le modèle <span className={"mr-1"}>Flatway</span>
        </h1>
        <p className={"text-[#737373] font-semibold text-lg text-center"}>
          FlatwayPro est une application mobile dédiée exclusivement aux
          professionnels de l’immobilier : agents immobiliers, mandataires,
          notaires et syndics de copropriété ainsi qu’à leurs collaborateurs.
        </p>
      </header>
      <p
        className={
          "text-[#737373] font-semibold text-lg text-center sm:px-0 px-2"
        }
      >
        Tout professionnel de l’immobilier peut s’inscrire sur FlatwayPro
        lorsque le service est disponible dans sa commune.
      </p>
      <div
        className={
          "lg:px-0 px-[10%] h-auto z-10 w-full flex lg:flex-row flex-col gap-10 justify-center"
        }
      >
        <section
          className={"w-full xl:w-[1200px] h-auto rounded-xl bg-slate-50 p-8"}
        >
          <h1 className={"text-xl font-bold h-auto"}>
            Les avantages avec <span className={"mr-[1px]"}>Flatway</span>
            <span>Pro</span>
          </h1>
          <ul
            className={"grid md:grid-cols-2 xl:grid-cols-3 gap-7 h-[80%] mt-10"}
          >
            {AdvantageContent.map((item, index) => (
              <ListItem text={item.text} icon={item.icon} key={index} />
            ))}
          </ul>
        </section>
      </div>
      <p className={"text-dark-red font-semibold text-lg text-center"}>
        Nos services libèrent votre temps et vous permettent de vous consacrer
        pleinement à votre métier de professionnel de l’immobilier,
        <br /> conseil et suivi pour les acheteurs et les vendeurs dès la
        signature d’un mandat en votre faveur.
      </p>
    </div>
  );
};

/**
 * @name PartnerSection
 * @description Section expliquant comment devenir partenaire
 * @constructor
 */
const PartnerSection = () => {
  const [select, setSelect] = useState(0);
  const sectionSelect = [<FirstSection />, <SecondSection />, <ThirdSection />];
  return (
    <div
      className={
        "h-auto w-full flex flex-col justify-center mt-10 items-center"
      }
    >
      <header
        className={`col-span-2 rounded-lg bg-white flex xl:w-[1200px] flex-col`}
      >
        <span className={"relative"}>
          <div
            className={` ${
              select === 0
                ? ""
                : `${select === 1 ? "translate-x-full" : "translate-x-[200%]"}`
            } h-full bg-lightredRgba w-1/3 absolute transition-all duration-300 rounded-lg`}
          />
          <ul className={"w-full flex items-start gap-10 text-center p-8"}>
            <li className={"w-[33%]"}>
              <button
                onClick={() => setSelect(0)}
                className={"flex flex-col items-center w-full"}
              >
                <h1
                  className={
                    "font-bold text-dark-red text-lg md:text-xl lg:text-2xl"
                  }
                >
                  1
                </h1>
                <span className={"font-semibold"}>
                  Enregistrer un mobile principal
                </span>
                <span className={"font-light text-xs italic tracking-tight"}>
                  pour administrer le compte
                </span>
                <IoIosArrowDown />
              </button>
            </li>
            <li className={"w-[33%]"}>
              <button
                onClick={() => setSelect(1)}
                className={"flex flex-col items-center w-full"}
              >
                <h1
                  className={
                    "font-bold text-dark-red text-lg md:text-xl lg:text-2xl"
                  }
                >
                  2
                </h1>
                <span className={"font-semibold"}>
                  Créer son espace partenaire
                </span>
                <span className={"font-light text-xs italic tracking-tight"}>
                  Coordonnées, équipe, secteur d’activité et barèmes
                  d’honoraires
                </span>
                <IoIosArrowDown />
              </button>
            </li>
            <li className={"w-[33%]"}>
              <button
                onClick={() => setSelect(2)}
                className={"flex flex-col items-center group w-full"}
              >
                <h1
                  className={
                    "font-bold text-dark-red text-lg md:text-xl lg:text-2xl"
                  }
                >
                  3
                </h1>
                <span className={"font-semibold"}>
                  Signer le contrat de partenariat
                </span>
                <span className={"font-light text-xs italic tracking-tight"}>
                  éléctroniquement
                </span>
                <IoIosArrowDown />
              </button>
            </li>
          </ul>
        </span>
        <section className={"bg-white py-5 px-10 rounded-lg w-full"}>
          {sectionSelect[select]}
        </section>
      </header>
      <main
        className={
          "sm:px-0 px-2 h-auto w-full flex flex-col lg:flex-row py-10 justify-center mt-10 bg-[url('./assets/EllipsePink.svg')] bg-no-repeat sm:bg-cover gap-10 items-center"
        }
      >
        <section
          className={
            "flex flex-col w-full lg:px-0 px-2 lg:w-[40%] h-auto gap-[1vw]"
          }
        >
          <div className={"w-full h-auto grid grid-cols-2 gap-6"}>
            <div className={"rounded-lg px-6 py-2 bg-white"}>
              <p className={"text-dark-red font-semibold text-lg text-left"}>
                <span className={"flex gap-2 items-center "}>
                  SANS
                  <SAlogo />
                </span>
              </p>
              <p className={"leading-6"}>
                <span className={"font-bold"}>Honoraires à taux réduits</span>
                <br />
                Visites assurées par le vendeur
                <br />
              </p>
              <p
                className={
                  "font-light text-clampIntroBottomContent italic tracking-tight leading-tight mt-3"
                }
              >
                Option à destination des vendeurs désireux d’assurer eux-mêmes
                les visites
              </p>
            </div>
            <div className={"rounded-lg px-6 py-2 bg-white"}>
              <p className={"text-dark-red font-semibold text-lg"}>AVEC</p>
              <p className={"text-black leading-6"}>
                <span className={"font-bold"}>Honoraires standards</span>
                <br />
                Visites assurées par vos soins
              </p>
            </div>
          </div>
          <p className={"text-dark-red font-bold"}>
            Le professionnel assure suivi et conseil pour les vendeurs et les
            acheteurs
            <span className={"text-lg"}>
              <br />
              jusqu’à la signature chez le notaire.
            </span>
          </p>
        </section>
        <aside
          className={"h-[700px] relative flex items-center justify-center"}
        >
          <img
            alt={"phone"}
            src={Phone}
            className={
              "hover:scale-110 transition-all duration-300 z-10 transform object-scale-down h-full drop-shadow-[0_15px_15px_rgba(0,0,0,0.4)]"
            }
          />
          <img
            src={blob3}
            alt={"blob"}
            className={"absolute h-[20vw] -top-[5vw] -right-[6vw]"}
          />
        </aside>
      </main>
    </div>
  );
};

/**
 * @name VisitSection
 * @description Section sur les visites expliquant le fonctionnement et l'implication des parties
 * @constructor
 */
export const VisitSection = () => {
  return (
    <div
      className={
        "h-auto xl:h-auto w-full flex items-center relative flex-col py-10"
      }
    >
      <img
        src={blob2}
        className={
          "absolute sm:block hidden h-[20vh] -top-[20vh] left-0 lg:-top-[50vh] lg:-left-[15vw] lg:h-[60vh]"
        }
        alt={"blob"}
      />
      <span
        className={
          "w-full lg:px-0 px-2 lg:w-[900px] flex flex-col items-center gap-5"
        }
      >
        <p className={"font-bold text-xl"}>
          Nous avons repensé les visites pour vous offrir une expérience à la
          fois moderne et sécurisée.
        </p>
        <span>
          <ul className={"font-medium flex flex-col gap-2"}>
            <li>
              Si vous organisez les visites, Flatway vous accompagne ; sinon,
              Flatway vous tient informé.
            </li>
            <li>
              Que les visites soient assurées par les vendeurs ou par vos
              collaborateurs, vous pouvez contacter les vendeurs et les
              acheteurs à tout moment depuis votre espace.
            </li>
          </ul>
        </span>
      </span>
      <div className={"w-full flex  justify-center gap-10 mt-10"}>
        <main
          className={
            "flex w-full 2xl:px-[10%] px-0 gap-10 flex-col md:flex-row xl:justify-center xl:ml-0 ml-2 2xl:justify-between overflow-x-auto"
          }
        >
          <VisiteCard title={"La demande de visite"} image={Demande}>
            <p className={"text-sm text-[#737373] font-medium leading-relaxed"}>
              L’acheteur consulte les annonces et propose des dates. <br />
              <br />
              La demande de visite est placée{" "}
              <span className={"text-dark-red underline"}>En attente</span> dans
              l’espace « Mes Echanges » des parties ; le vendeur et le
              partenaire sont notifiés.
              <br />
              <br />
              Si la visite est assurée par le partenaire, il choisit la date de
              visite, l’affecte à un membre de son équipe et le vendeur est
              informé. Sinon, le vendeur choisit la date et le partenaire est
              informé.
            </p>
          </VisiteCard>
          <VisiteCard title={"La visite planifiée"} image={VisiteP}>
            <p className={"text-sm text-[#737373] font-medium leading-relaxed"}>
              La date de visite confirmée est placée en{" "}
              <span className={"text-dark-red underline"}>Planifier</span> dans
              les espaces ‘Mes Echanges’.
              <br />
              <br /> Si une des parties annule ou déplace le rendez-vous, les
              autres parties sont aussitôt notifiées.
            </p>
          </VisiteCard>
          <VisiteCard title={"La visite"} image={Visite}>
            <p className={"text-sm text-[#737373] font-medium leading-relaxed"}>
              L’acheteur confirme sa présence.
              <br />
              <br /> Un point de rencontre, choisi par le vendeur ou par le
              partenaire, est ensuite communiqué à l’acheteur.
              <br />
              <br /> L’identité de l’acheteur est confirmée grâce au QR code
              accessible depuis l’espace Visite.
            </p>
          </VisiteCard>
        </main>
      </div>
    </div>
  );
};

/**
 * @name OfferSection
 * @description Section sur les offres et le rôle du partenaire
 * @constructor
 */
const OfferSection = () => {
  const [Select, setSelect] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={"h-auto flex flex-col relative items-center"}>
      <img
        src={blob}
        className={"absolute -top-[20vh] -right-[30vh] h-[60vh] -z-10"}
        alt={"blob sm:block hidden"}
      />
      <header>
        <p className={"font-semibold text-center h-auto mb-10"}>
          Acheteurs et vendeurs négocient directement sans intermédiaire depuis
          l'espace dédié.
          <br />
          <br />
          <span className={"font-bold text-dark-red"}>
            Le partenaire dispose d’un accès instantané aux offres et intervient
            pleinement comme conseil pour les parties.
          </span>
        </p>
      </header>
      <section
        className={
          "flex w-full lg:flex-row lg:gap-0 gap-5 flex-col justify-center"
        }
      >
        <div
          className={
            "lg:w-[30vw] flex flex-col justify-center gap-5 lg:px-0 px-2"
          }
        >
          <label className={"text-lg font-bold"}>Le partenaire</label>
          <ul className={"flex-col text-sm flex gap-16 font-medium"}>
            <li className={`${Select === 0 ? "text-dark-red" : ""}`}>
              <button
                onClick={() => {
                  setIsOpen(false);
                  setSelect(0);
                }}
                className={
                  "bg-slate-50 py-1 active:bg-light-red transition-all duration-300 px-2 rounded-xl hover:bg-slate-200 w-full flex justify-between items-center"
                }
              >
                Peut demander une attestation de capacité d’emprunt
                <IoIosArrowForward className={"text-xl"} />
              </button>
            </li>
            <li className={`${Select === 1 ? "text-dark-red" : ""}`}>
              <button
                onClick={() => {
                  setIsOpen(false);
                  setSelect(1);
                }}
                className={
                  "bg-slate-50 py-1 active:bg-light-red transition-all duration-300 px-2 rounded-xl hover:bg-slate-200 w-full flex justify-between items-center"
                }
              >
                Peut ajuster ses honoraires depuis son espace
                <IoIosArrowForward className={"text-xl"} />
              </button>
            </li>
            <li className={`${Select === 2 ? "text-dark-red" : ""}`}>
              <button
                onClick={() => {
                  setIsOpen(true);
                  setSelect(2);
                }}
                className={
                  "bg-slate-50 py-1 active:bg-light-red transition-all duration-300 px-2 rounded-xl hover:bg-slate-200 w-full flex justify-between text-left items-center"
                }
              >
                Lorsqu'une offre est acceptée, le partenaire transmet le dossier
                au notaire <IoIosArrowForward className={"text-xl"} />
              </button>
              <p className={`${isOpen ? "block" : "hidden"} text-black`}>
                Jusqu'à ce que le professionnel transmette le dossier au notaire
                et informe Flatway, d'autres utilisateurs peuvent soumettre des
                offres.
              </p>
            </li>
          </ul>
        </div>
        <aside
          className={
            "relative h-[60vh] w-full lg:w-[50vw] justify-evenly select-none flex items-center"
          }
        >
          <button
            onClick={() => {
              setIsOpen(false);
              setSelect(1);
            }}
            className={`${
              Select === 1 ? "z-10 absolute" : "p-10 brightness-50"
            } h-full z-10 transition-all duration-300`}
          >
            <img
              src={partner}
              alt={"partner"}
              className={"object-scale-down max-h-full drop-shadow-bottom"}
            />
          </button>
          <button
            onClick={() => {
              setIsOpen(false);
              setSelect(0);
            }}
            className={` h-full transition-all duration-300 ${
              Select === 0
                ? "z-10 absolute"
                : Select !== 2
                ? "p-10 brightness-50"
                : "p-10 brightness-50"
            }`}
          >
            <img
              alt={"Emprunt"}
              src={Emprunt}
              className={"object-scale-down max-h-full"}
            />
          </button>
          <button
            onClick={() => {
              setIsOpen(true);
              setSelect(2);
            }}
            className={`h-full ${
              Select === 2 ? "z-10 absolute" : "p-10 brightness-50"
            } transition-all duration-300`}
          >
            <img
              alt={"acpt"}
              src={accept}
              className={"object-scale-down max-h-full"}
            />
          </button>
        </aside>
      </section>
    </div>
  );
};

/**
 * @name FlatWayProComponent
 * @description Composant principal de la page FlatwayPro
 * @param title
 * @param desc
 * @param index
 * @constructor
 */
const FlatWayProComponent = ({
  title,
  desc,
  index,
}: {
  title: string;
  desc: string;
  index: number;
}) => {
  const sections = [<PartnerSection />, <VisitSection />, <OfferSection />];

  return (
    <div
      className={`${
        title === "Devenir Partenaire" ? "bg-slate-50 py-10" : ""
      } h-auto flex flex-col items-center w-full`}
    >
      <header className={"h-auto flex flex-col items-center"}>
        <h1
          className={
            "text-3xl sm:text-clampProIntroTitle text-darkRedTitle leading-tight text-center font-bold"
          }
        >
          {title}
        </h1>
        <h2
          className={
            "xl:w-[1200px] text-lg xl:text-[1vw] font-semibold text-[#737373] text-center"
          }
        >
          {desc}
        </h2>
      </header>
      <div className={"font-medium text-sm w-full"}>{sections[index]}</div>
    </div>
  );
};

/**
 * @name ListItem
 * @description Item de la liste des avantages de FlatwayPro
 * @param text
 * @param icon
 * @constructor
 */
export const ListItem = ({
  text,
  icon,
}: {
  text: string;
  icon: React.JSX.Element;
}) => {
  return (
    <li
      className={
        "flex flex-col hover:text-dark-red translation-all duration-300"
      }
    >
      <span>{icon}</span>
      <span>{text}</span>
    </li>
  );
};

const FirstSection = () => {
  return (
    <ul className={"list-disc ml-4"}>
      <li>
        Lorsque le service est disponible dans sa commune, le professionnel
        immobilier détenant une carte de transaction ou de gestion peut
        s’inscrire sur l’application FlatwayPro.
      </li>
      <li className={"mt-3"}>
        Depuis le mobile principal, le partenaire peut gérer son compte, ajouter
        ou retirer un mobile collaborateur, ajuster ses honoraires ou son
        secteur d'activité et organiser ses visites.
      </li>
    </ul>
  );
};

const SecondSection = () => {
  return (
    <>
      <ul className={"list-disc ml-4 flex flex-col gap-2"}>
        <li>Inscrire par simples clics les coordonnées de votre enseigne</li>
        <li>
          Attacher à votre compte un ou deux mobiles supplémentaires pour vos
          collaborateurs
        </li>
        <li>
          Définir votre zone d'activité : en plus de la commune de votre
          enseigne, vous avez la possibilité d'ajouter une commune voisine par
          mobile enregistré, y compris le mobile principal.
        </li>
        <li className={"xl:leading-7"}>
          Renseigner votre barème d’honoraires habituels et/ou un barème
          d’honoraires à taux réduits à destination des vendeurs désireux
          d’assurer eux-mêmes les visites.
          <br /> Dans les deux cas vous êtes l’unique interlocuteur des vendeurs
          et des acheteurs
        </li>
      </ul>
      <p className={"font-bold"}>
        Votre enseigne apparait dans les résultats de recherche des communes
        choisies par les vendeurs.
      </p>
    </>
  );
};

const ThirdSection = () => {
  return (
    <ul className={"list-disc ml-4 flex flex-col gap-2"}>
      <li>
        Flatway met à disposition des professionnels des outils de gestion de
        mandats, de visites et un portail pour diffuser et centraliser les
        annonces
      </li>
      <li>
        Nos services sont payants pour les professionnels : lors de la signature
        chez le notaire, le professionnel qui réalise la vente reverse à Flatway
        des frais de services.
      </li>
      <li>
        Afin de ne pas favoriser un professionnel plus qu’un autre ou une
        annonce plus qu’une autre, Flatway ne propose aucune offre payante de
        référencement des annonces et des annonceurs.
      </li>
    </ul>
  );
};

export default FlatWayProComponent;
