import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import Modify from "../../assets/undraw_edit_photo_re_ton4.svg";
import Duplicate from "../../assets/undraw_duplicate_re_d39g.svg";
import Delete from "../../assets/undraw_swipe_options_2e4v.svg";
import listImg from "../../assets/shutterstock_146162657.jpg";
import Geometry from "../../assets/geometry/blob.svg";
import Geometry2 from "../../assets/geometry/blob2.svg";
import Vendre from "../../assets/vente-steven-2.jpg";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { MdOutlineSell, MdOutlineShowChart } from "react-icons/md";
import { TbMapPins } from "react-icons/tb";
import { LuCopy } from "react-icons/lu";

/**
 * @name GestionSection
 * @description Section de gestion
 * @param children
 * @param title
 * @param subtitle
 * @param image
 * @constructor
 */
const GestionSection = ({
  children,
  title,
  subtitle,
  image,
}: {
  children: React.JSX.Element;
  title: string;
  subtitle: string;
  image: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <span className={`h-full w-full lg:w-2/3 justify-center flex flex-col`}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={
            "flex bg-slate-50 py-1 active:bg-light-red transition-all duration-300 px-2 rounded-xl hover:bg-slate-200 text-start items-center justify-between w-full"
          }
        >
          <span>
            <h1
              className={`${
                isOpen ? "text-lg" : "text-2xl"
              } font-bold text-dark-red text-start transition-all duration-300`}
            >
              {title}
            </h1>
            <p>{subtitle}</p>
          </span>
          <IoIosArrowForward
            className={`text-[20px] text-dark-red transition-all duration-300 ${
              isOpen ? "rotate-90" : ""
            }`}
          />
        </button>
        <span className={`${isOpen ? "block" : "hidden"}`}>{children}</span>
      </span>
    </>
  );
};

/**
 * @name GestionSections
 * @description Regroupement des sections de gestion
 * @constructor
 */
export const GestionSections = () => {
  return (
    <>
      <li className={"flex justify-between"}>
        <GestionSection
          title={"Modifier les annonces"}
          subtitle={"Seul le prix de vente net vendeur peut être modifié :"}
          image={Modify}
        >
          <ul className={"list-disc flex flex-col gap-2 mt-2 ml-6 text-sm"}>
            <li>
              la modification du prix de vente net vendeur entraine
              automatiquement l’ajustement des honoraires du professionnel qui
              seront recalculés au prorata du nouveau prix ; aucune modification
              n’est nécessaire au mandat de vente
            </li>
            <li>
              si votre bien est en vente chez plusieurs professionnels sur
              l’application Flatway, le changement de prix net vendeur chez un
              professionnel entraine le changement de prix net vendeur pour les
              autres également
            </li>
          </ul>
        </GestionSection>
        <div className={"lg:block hidden h-40 w-40 animate-blob bg-dark-red"} />
      </li>
      <li className={"flex justify-between"}>
        <div
          className={"lg:block hidden h-40 w-40 animate-blob2 bg-light-red"}
        />
        <GestionSection
          title={"Confier la vente à un autre professionnel"}
          subtitle={
            "Par simple duplication de l’annonce, le vendeur peut confier la vente à un autre, voire plusieurs autres professionnels."
          }
          image={Duplicate}
        >
          <>
            Le vendeur n’a pas besoin de recommencer toutes les étapes
            d’enregistrement, il lui suffit de :
            <ul
              className={"list-decimal ml-6 flex flex-col gap-2 my-2 text-sm"}
            >
              <li>
                Choisir dans la liste un nouveau professionnel avec le même type
                d’honoraires. Le descriptif du bien, les photos et le prix net
                vendeur déjà fournis pour le premier professionnel restent
                inchangés.
                <br />
                l’annonce apparait identique à la première, exception faite du
                prix de vente final qui varie donc selon le taux d’honoraires de
                chaque professionnel.
              </li>
              <li>
                Signer le mandat de vente avec le nouveau
                professionnel.
              </li>
            </ul>
            Le vendeur peut dupliquer la vente de son bien jusqu’à 3 fois.
          </>
        </GestionSection>
      </li>
      <li className={"flex justify-between"}>
        <GestionSection
          title={"Supprimer votre annonce"}
          subtitle={
            "À tout moment et en respectant les délais légaux - voir mandat sans exclusivité et résiliation - le vendeur peut mettre fin à la vente et supprimer son annonce."
          }
          image={Delete}
        >
          <p className={"mt-2"}>
            Si le bien est en vente chez plusieurs professionnels, le vendeur,
            en respectant les délais légaux, peut supprimer les annonces chez
            les professionnels auprès desquels il a résilié le mandat de vente.
          </p>
        </GestionSection>
        <div
          className={
            "lg:block hidden h-40 w-40 rounded-customBlobTriangle bg-dark-red"
          }
        />
      </li>
    </>
  );
};

/**
 * @name SecondSection
 * @description Deuxième section de la page de vente (commercialisation)
 * @constructor
 */
export const SecondSection = () => {
  return (
    <section
      className={
        "flex lg:flex-row flex-col-reverse bg-slate-50 w-full justify-center gap-10 lg:gap-4 items-center py-10"
      }
    >
      <span className={"flex flex-col gap-4 w-full lg:w-[600px] lg:px-0 px-2"}>
        <h1 className={"font-bold text-xl md:text-3xl"}>
          La commercialisation de votre bien
        </h1>
        <p>
          Après la signature des deux parties, la vente est mise en ligne :
          <ul className={"list-disc ml-6"}>
            <li>
              le professionnel utilise la plateforme Flatway et les supports de
              publicité habituels pour commercialiser le bien
            </li>
            <li>
              le vendeur prépare les documents à transmettre au professionnel
              (charges, assemblées, diagnostics, etc.), documents consultables
              par les acheteurs intéressés, après la visite du bien
            </li>
          </ul>
        </p>
      </span>
      <aside className={"w-full lg:px-0 px-2 lg:w-[500px]"}>
        <img
          alt={"Commercialisation vente"}
          src={listImg}
          className={"w-full object-cover rounded-xl"}
        />
      </aside>
    </section>
  );
};

/**
 * @name HowToSellHeader
 * @description Header de la page de vente
 * @constructor
 */
export const HowToSellHeader = () => {
  const sellAdvantageContent = [
    {
      text: "Vendre en toute sécurité : les données ne sont connues que du professionnel choisi",
      icon: <IoShieldCheckmarkOutline className="text-[30px]" />,
    },
    {
      text: "Vendre au juste prix en s’appuyant sur notre estimation",
      icon: <MdOutlineSell className="text-[30px]" />,
    },
    {
      text: "Optimiser les coûts d’honoraires, en comparant les honoraires des professionnels",
      icon: <MdOutlineShowChart className="text-[30px]" />,
    },
    {
      text: "Gagner du temps, aucun déplacement chez le professionnel n’est obligatoire",
      icon: <TbMapPins className="text-[30px]" />,
    },
    {
      text: "Confier la vente à plusieurs professionnels par simple duplication de l’annonce",
      icon: <LuCopy className="text-[30px]" />,
    },
  ];

  return (
    <header
      className={
        "bg-gradient-to-br overflow-hidden relative via-dark-red to-dark-red from-[#d73e3e] py-10 h-auto flex flex-col items-center w-full"
      }
    >
      <img
        src={Geometry}
        alt={"Blob"}
        className={
          "select-none w-[400px] absolute top-0 z-0 -left-[9vw] drop-shadow-lg"
        }
      />
      <img
        src={Geometry2}
        alt={"Blob"}
        className={
          "w-[600px] select-none absolute z-0 bottom-[-10vw] drop-shadow-lg"
        }
      />
      <div
        className={
          "h-auto xl:px-0 px-4 xl:grid xl:grid-cols-flatwaySection z-10 text-white xl:w-[1200px] items-center font-bold gap-10 justify-center"
        }
      >
        <h1 className={"text-2xl md:text-4xl col-span-6 w-full"}>
          Nous nous employons à réduire vos coûts, à simplifier et sécuriser la
          vente de votre bien
        </h1>
        <aside
          className={"col-span-6 xl:mt-0 mt-10 relative h-[300px] sm:h-[500px]"}
        >
          <img
            alt={"Vendre"}
            src={Vendre}
            className={
              "w-full h-full object-cover absolute z-20 rounded-customBlobImage"
            }
          />
          <div
            className={
              "rounded-customBlobTriangle3 top-0 z-10 h-[200px] absolute w-[200px] bg-light-red"
            }
          />
        </aside>
      </div>
      <section
        className={
          "w-full flex flex-col z-10 text-white py-10 justify-center items-center"
        }
      >
        <span>
          <ul
            className={
              "font-medium sm:grid grid-cols-2 xl:px-0 px-2 gap-5 w-full xl:w-[1200px]"
            }
          >
            {sellAdvantageContent.map((item, index) => (
              <li
                key={index}
                className={
                  "gap-3 flex flex-col items-start sm:mt-0 mt-4 xl:w-[25vw] justify-start"
                }
              >
                {item.icon}
                {item.text}
              </li>
            ))}
          </ul>
        </span>
      </section>
    </header>
  );
};
