import React, { useState } from "react";
import {
  FlatwaySection,
  FlatwaySectionTemplate,
} from "../../utils/FlatWay.utils";
import { FlatwaySectionId } from "../../interfaces/Flatway.sectionId";
import Vendre from "../../assets/vente-steven-2.jpg";
import { IoIosArrowForward } from "react-icons/io";

/**
 * @name FlatWaySellComponent
 * @description Section de vente de la page Flatway
 * @constructor
 */
export const FlatWaySellComponent = () => {
  const sellAdvantageContent = [
    "Consulter la liste des professionnels et comparer leurs honoraires",
    "Assurer les visites et bénéficier des honoraires à taux réduits",
    "Confier la vente à un ou plusieurs professionnels de votre choix, sans se déplacer",
    "Vendre au juste prix en s’appuyant sur notre estimation",
    "Signer électroniquement le mandat de vente avec le professionnel",
    "Gérer les rendez-vous et visites à partir de l’espace client",
  ];

  return (
    <div className={"sm:h-auto w-full pt-[80px]"} id={FlatwaySectionId.vendre}>
      <FlatwaySection
        title={
          "Flatway vous accompagne dans la vente et simplifie vos démarches"
        }
        route={"/comment-vendre"}
        image={Vendre}
        list={sellAdvantageContent}
      />
    </div>
  );
};

/**
 * @name BubblePrep
 * @description Section de préparation de la vente de la roadmap de Vendre
 * @constructor
 */
const BubblePrep = () => {
  return (
    <FlatwaySectionTemplate
      title={"Préparer la vente"}
      description={
        "Avant de procéder à l’enregistrement de votre annonce, nous vous conseillons de bien préparer la vente."
      }
    >
      <>
        <label>1. Flatway vous fournit</label>
        <ul className={"list-disc ml-6"}>
          <li>une estimation du prix au m² à votre adresse</li>
          <li>la liste des biens vendus à votre adresse et à proximité</li>
          <li>une estimation de votre bien</li>
        </ul>
        <p className={"mt-2"}>2. Définir votre prix net vendeur¹</p>
        <ul className={"list-disc ml-6"}>
          <li>utiliser notre estimation</li>
          <li>
            demander à un professionnel de votre choix une autre estimation
          </li>
          <li>comparer les estimations</li>
          <li>décider de votre prix net vendeur</li>
        </ul>
        <p className={"mt-2"}>3. Diagnostics obligatoires</p>
        <p className={"ml-4"}>
          Faire appel à un expert certifié et effectuer les diagnostics
          obligatoires
        </p>
        <p className={"mt-2"}>4. Images pour la mise en vente</p>
        <ul className={"ml-6 list-disc"}>
          <li>
            Préparer des images représentatives de votre bien, les images
            déformées sont contre-productives
          </li>
          <li>
            Le professionnel sélectionné pour la vente peut également vous
            proposer des photos de qualité
          </li>
        </ul>
        <p className={"text-xs"}>
          ¹Prix de vente = Prix net vendeur + honoraires du professionnel
        </p>
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name BubbleOne
 * @description Section 1 de la roadmap de Vendre (Choisir un professionnel dans la liste)
 * @constructor
 */
const BubbleOne = () => {
  return (
    <FlatwaySectionTemplate
      title={"Choisir un professionnel dans la liste"}
      description={
        "Le vendeur est libre de choisir un professionnel dans la liste ou, à défaut, de demander au professionnel souhaité de s’inscrire sur FlatwayPro."
      }
    >
      <>
        <ul className={""}>
          La liste des professionnels est affichée en deux catégories distinctes
          :
          <ul className={"list-disc ml-6 my-1"}>
            <li>
              les professionnels ayant au moins un bien en vente sur Flatway
            </li>
            <li>les professionnels n’ayant aucun bien en vente sur Flatway</li>
          </ul>
          La première catégorie est affichée en premier et l’ensemble des
          résultats de recherche de chaque catégorie est classé aléatoirement
        </ul>
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name BubbleTwo
 * @description Section 2 de la roadmap de Vendre (Choisir un type d’honoraires)
 * @constructor
 */
const BubbleTwo = () => {
  return (
    <FlatwaySectionTemplate
      title={"Choisir un type d’honoraires"}
      description={
        "Assurer les visites et bénéficier des honoraires à taux réduits ou confier les visites au professionnel et payer des honoraires standards."
      }
    >
      <>
        <p className={"text-dark-red font-medium"}>
          Le professionnel assure suivi et conseil pour les vendeurs et les
          acheteurs jusqu’à la signature chez le notaire.
        </p>
        Les barèmes d’honoraires sont déclinés en deux types :
        <ul className={"list-disc ml-6 my-1"}>
          <li>barème d’honoraires standards</li>
          <li>
            barème d’honoraires à taux réduits à destination des vendeurs
            désireux d’assurer eux-mêmes les visites en lieu et place du
            professionnel
          </li>
        </ul>
        Les professionnels ont le libre choix de proposer aux vendeurs un des
        deux barèmes ou les deux barèmes à la fois.
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name BubbleThree
 * @description Section 3 de la roadmap de Vendre (Enregistrer l'annonce)
 * @constructor
 */
const BubbleThree = () => {
  return (
    <FlatwaySectionTemplate
      title={"Enregistrer l'annonce"}
      description={
        "Par simples clics, votre annonce apparaît détaillée et sans aucun superflu."
      }
    >
      <>
        À partir de votre mobile :
        <ul className={"list-disc ml-6"}>
          <li>renseigner l’adresse du bien</li>
          <li>
            choisir le professionnel à qui vous souhaitez confier la vente
          </li>
          <li>renseigner le type du bien : appartement, maison, etc.</li>
          <li>
            enregistrer la surface du bien, le prix net vendeur retenu et les
            charges mensuelles
          </li>
          <li>
            choisir le type d’honoraires qui vous convient, taux réduits ou
            standards
          </li>
          <li>
            renseigner par simples clics le descriptif de votre bien : nombre de
            pièces, de chambres et de salles de bains, etc.
          </li>
          <li>
            ajouter jusqu’à 15 photos représentatives de votre bien, de votre
            bibliothèque ou prendre des photos à partir de votre mobile
          </li>
          <li>voir l’aperçu de votre annonce et ‘valider’</li>
        </ul>
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name BubbleFor
 * @description Section 4 de la roadmap de Vendre (Signer le mandat de vente)
 * @constructor
 */
const BubbleFor = () => {
  const [isOpenMandat, setIsOpenMandat] = useState(false);

  return (
    <FlatwaySectionTemplate
      title={"Signer le mandat de vente"}
      description={
        "Flatway vous permet ensuite de signer électroniquement avec le professionnel choisi le mandat de vente."
      }
    >
      <>
        Pour établir le mandat, le propriétaire du bien scanne sa pièce
        d’identité ainsi que la taxe foncière relative à ce bien :
        <div className={"ml-6"}>
          <ul className={"list-disc my-1"}>
            <li>
              après vérification, le mandat de vente à signer électroniquement
              vous sera envoyé
            </li>
            <li>
              le mandat est ensuite envoyé au professionnel pour signature
              électronique
            </li>
            <li>
              le mandat signé par les deux parties est finalemennt envoyé au
              vendeur et au professionnel
            </li>
          </ul>
          <button
            onClick={() => setIsOpenMandat(!isOpenMandat)}
            className={
              "font-bold flex items-center pr-5 justify-between w-full"
            }
          >
            Mandat sans exclusivité, ce qu’il faut savoir :
            <IoIosArrowForward
              className={`transition-all text-base duration-300 ${
                isOpenMandat ? "rotate-90" : ""
              }`}
            />
          </button>
          <span className={`${isOpenMandat ? "block" : "hidden"}`}>
            Le mandat de vente permet de faire appel à un ou
            plusieurs professionnels en même temps, pour vendre son bien avec un
            minimum de contraintes. Il permet aussi au vendeur de réaliser une
            vente directe entre particuliers.
            <br />{" "}
            <span className={"font-bold"}>
              Durée du mandat, engagement et résiliation :
            </span>
            <br /> Le mandat de vente est consenti pour une
            durée initiale irrévocable de{" "}
            <span className={"font-bold"}>trois mois</span>.<br /> Si le mandat
            n’a pas été dénoncé dans un délai de 14 jours après la signature ou
            à l’expiration de cette période initiale, il sera prorogé pour une
            durée maximale de 12 mois supplémentaires au terme de laquelle il
            prendra automatiquement fin.
            <br /> Le vendeur quel que soit le motif, peut résilier, par lettre
            recommandée, le mandat de vente :
            <ul className={"list-disc ml-6 my-1"}>
              <li>
                dans un délai de 14 jours après la signature du mandat (Article
                1122 du code civil)
              </li>
              <li>
                au moins 15 jours avant la fin de la période initiale de trois
                mois
              </li>
              <li>
                à tout moment après la période initiale de trois mois, moyennant
                un préavis de 15 jours
              </li>
            </ul>
          </span>
        </div>
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name BubbleList
 * @description Liste des bulles de la roadmap de Vendre
 */
export const BubbleList = [
  <BubblePrep />,
  <BubbleOne />,
  <BubbleTwo />,
  <BubbleThree />,
  <BubbleFor />,
];
