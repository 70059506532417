import estimation1 from "../assets/2 - estimation/Estimation.png";
import estimation2 from "../assets/2 - estimation/Estimation avancee.png";
import estimation3 from "../assets/2 - estimation/liste Biens vendus.png";
import { useEffect, useState } from "react";

/**
 * @name useFlatwayEstimation
 * @description Custom hook pour l'estimation dans la page d'accueil
 */
export function useFlatwayEstimation() {
  const images = [estimation1, estimation2, estimation3];
  const [counter, setCounter] = useState(0);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (hover) return;
    const interval = setInterval(() => {
      setCounter((prevCounter) =>
        prevCounter === images.length - 1 ? 0 : prevCounter + 1,
      );
    }, 2500);
    return () => clearInterval(interval);
  }, [hover, images.length]);

  return {
    images,
    counter,
    setHover,
  };
}
