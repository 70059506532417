import React, { useEffect } from "react";
import { sellcityInfo } from "../../interfaces/interface";
import { InfoItem } from "../../utils/Estimation.utils";
import { handleChangeSell } from "../../utils/Vendre.utils";
import { IoClose } from "react-icons/io5";
import { useVendreHeader } from "../../hooks/Vendre.hooks";
import { gsap } from "gsap";
import { PartnerAgent } from "../../interfaces/Vendre";

/**
 * @name DisplaySellList
 * @description Affiche la list de l'autocomplete pour la vente
 * @param focus
 * @param cityList
 * @param setUserInput
 * @param setSelectCity
 * @param setFocus
 * @constructor
 */
export const DisplaySellList = ({
  focus,
  cityList,
  setUserInput,
  setSelectCity,
  setFocus,
}: {
  focus: boolean;
  cityList: never[];
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
  setSelectCity: React.Dispatch<React.SetStateAction<sellcityInfo | undefined>>;
  setFocus: (args: boolean) => void;
}) => {
  return (
    <div
      className={`absolute top-full right-0 z-10 mt-2 rounded-lg shadow border bg-white border-[#BABABA] w-full max-h[140] 
               ${focus && cityList.length > 0 ? "block" : "hidden"}
                `}
    >
      {cityList.map(({ city, zipcode, statusdeploy }, index) => (
        <button
          onFocus={(e) => {
            console.log(city);
            setUserInput(`${city}  ${zipcode}`);
            e.preventDefault();
            e.stopPropagation();
            setSelectCity({
              name: city != "Paris" ? city : `${city} (${zipcode})`,
              index: index,
              zipcode: zipcode,
              statusdeploy: statusdeploy,
            });
            setFocus(false);
          }}
          onTouchStart={(e) => {
            console.log(city);
            setUserInput(`${city}  ${zipcode}`);
            e.preventDefault();
            e.stopPropagation();
            setSelectCity({
              name: city,
              index: index,
              zipcode: zipcode,
              statusdeploy: statusdeploy,
            });
            setFocus(false);
          }}
          onClick={(e) => {
            setUserInput(`${city}  ${zipcode}`);
            console.log(city);
            e.preventDefault();
            e.stopPropagation();
            setSelectCity({
              name: city,
              index: index,
              zipcode: zipcode,
              statusdeploy: statusdeploy,
            });
            setFocus(false);
          }}
          className="h-[30px] w-full hover:bg-[#BABABA] px-2"
          key={index}
        >
          <div className="h-full w-full flex items-center justify-between">
            <h1>{city}</h1>
            <h2 className={`${statusdeploy ? "" : "text-dark-red"}`}>
              {statusdeploy ? zipcode : "Service indisponible"}
            </h2>
          </div>
        </button>
      ))}
    </div>
  );
};

/**
 * @name SellHeader
 * @description Header de la page de vente
 * @constructor
 */
const SellHeader = () => {
  useEffect(() => {
    const words = gsap.utils.toArray("h4") as HTMLElement[];
    const tl = gsap.timeline({ repeat: -1, duration: 50 }); // Start the timeline

    words.forEach((word, index) => {
      tl.from(
        word,
        {
          opacity: 0,
          y: 20,
          rotateX: -90,
        },
        "<",
      ).to(
        word,
        {
          opacity: 0,
          y: -20,
          rotateX: 90,
        },
        `<${index * 1 + 0.9}`,
      );
    });
  }, []);

  return (
    <>
      <h1 className="font-bold text-4xl mb-5">Vendre en 4 étapes</h1>
      <h4
        className={"animation text-dark-red font-bold m-0 text-xl leading-[0]"}
      >
        1 Choisir un professionnel
      </h4>
      <h4
        className={"animation text-dark-red font-bold m-0 text-xl leading-[0]"}
      >
        2 Choisir le type d’honoraires
      </h4>
      <h4
        className={"animation text-dark-red font-bold m-0 text-xl leading-[0]"}
      >
        3 Enregistrer l’annonce
      </h4>
      <h4
        className={"animation text-dark-red font-bold m-0 text-xl leading-[0]"}
      >
        4 signer le mandat de vente
      </h4>
    </>
  );
};

/**
 * @name VendreInput
 * @description Input de la page de vente
 * @param setData
 * @constructor
 */
export const VendreInput = ({
  setData,
}: {
  setData: React.Dispatch<React.SetStateAction<PartnerAgent[] | undefined>>;
}) => {
  const {
    handlePartnerSearch,
    setSelectCity,
    searchBarRef,
    focus,
    setFocus,
    userInput,
    setUserInput,
    cityList,
    setCityList,
  } = useVendreHeader(setData);

  return (
    <header
      className={
        "w-full lg:py-0 py-5 lg:h-[80vh] flex justify-center px-2 sm:px-[20%] flex-col"
      }
    >
      <SellHeader />
      <div
        ref={searchBarRef}
        className={"relative h-auto w-auto focus:outline-none"}
      >
        <div
          className={`transition-all relative group duration-300 mt-10 rounded-lg bg-white p-[.5em] border flex flex-row min-h-[49px] h-[49px] w-full`}
        >
          <span className={"w-full flex justify-between h-full items-center"}>
            <input
              value={userInput}
              placeholder={"Ex : Paris, 75019"}
              onChange={(e) =>
                handleChangeSell(e, setCityList, userInput, setUserInput)
              }
              onFocus={() => setFocus(true)}
              className={`focus:outline-none sm:mr-2 w-full`}
            />
            <button
              className={`${userInput.length > 0 ? "block" : "hidden"}`}
              onClick={() => {
                setUserInput("");
                setCityList([]);
              }}
            >
              <IoClose className={"text-[#737373]"} />
            </button>
          </span>
          <DisplaySellList
            focus={focus}
            cityList={cityList}
            setUserInput={setUserInput}
            setSelectCity={setSelectCity}
            setFocus={setFocus}
          />
        </div>
        <button
          className={`transition-all w-fit duration-300 text-white bg-dark-red font-medium my-3.5 px-4 py-2 rounded-lg`}
          onClick={() => {
            handlePartnerSearch();
          }}
        >
          Rechercher
        </button>
      </div>
    </header>
  );
};

/**
 * @name VendreContent
 * @description Contenu de la page de vente
 * @constructor
 */
export const VendreContent = () => {
  return (
    <div
      className={`h-auto w-full flex flex-col gap-20 items-center justify-center px-10`}
    >
      <section className="flex flex-col items-center justify-center h-auto text-sm gap-5">
        <header>
          <h1 className={"font-bold text-darkRedTitle text-3xl"}>
            Comment vendre ?
          </h1>
          <p className={"text-white-bubble"}>_</p>
        </header>
        <main className={"h-full justify-evenly flex flex-col gap-[15vh]"}>
          <InfoItem
            title={"Choisir un professionnel"}
            text={
              "Chaque professionnel propose un barème d’honoraires décliné en deux types : honoraires standards et/ou honoraires à taux réduits"
            }
          />
          <section>
            <h1 className="font-bold text-xl">Choisir le type d’honoraires</h1>
            <p className="text-base">
              <span className={"font-bold"}>À taux réduits</span> : visites
              assurées par le vendeur
              <br />
              <span className={"font-bold"}>Standards</span> : visites assurées
              par le professionnel
              <br />
              <br />{" "}
              <span className={"text-dark-red"}>
                Dans les deux cas et jusqu’à la signature chez le notaire
              </span>
              , le professionnel est l’interlocuteur de l’acheteur et du
              vendeur. Il assure suivi, conseil et renseigne les acheteurs sur
              tous documents relatifs au bien.
            </p>
          </section>
          <InfoItem
            title={"Enregistrer l’annonce"}
            text={
              "par simples clics, votre annonce apparait détaillée et sans aucun superflu"
            }
          />
          <InfoItem
            title={"Signer le mandat de vente"}
            text={
              "Mandat de vente à signer par le professionnel choisi et le vendeur"
            }
          />
          <p>
            Les services Flatway sont actuellement actifs en région Île de
            France
          </p>
        </main>
      </section>
    </div>
  );
};
